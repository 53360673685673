<template>
  <v-overlay
    opacity="0.85"
    color="secondary"
    z-index="0"
    :value="loading"
  >
    <v-row class="text-center primary--text">
      <v-col>
        <div class="subtitle-1 font-weight-bold">
          {{ $t('loading') }}
        </div>
        <br>
        <v-progress-circular
          :size="50"
          :width="7"
          indeterminate
          color="primary"
        />
      </v-col>
    </v-row>
  </v-overlay>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'LoadingResult',
  computed: {
    ...mapGetters('app', [
      'loading',
    ]),
  },
};
</script>
